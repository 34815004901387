import { gsap } from "gsap";

import Cards from './cards';
import Cutouts from './cutouts';
import ScrollAnimations from './scroll-animations';


const isDisabled = false;

export default class Main {

	constructor() {


		// dom elements
		this.loader = document.querySelector( '#loader' );

		// cards
		let cardElements = Array.from( document.querySelector( '.card-gallery' ).children );
		this.cards = new Cards( cardElements, false );

		let galleryElement = document.querySelector( '.cutout-gallery' );
		this.cutouts = new Cutouts( galleryElement, false );

		let titles = document.querySelectorAll( '.large-title' );
		let cutouts = document.querySelectorAll( '.cutout-gallery .row' );
		let scrollAnimations = new ScrollAnimations( {
			titles: titles,
			cutouts: cutouts
		} );


	}

	loaded() {

		// animate up inital title
		gsap.from( '.we-act', {
			y: 100,
			autoAlpha: 0,
			delay: .275,
			duration: .65
		} );

		// hide loader
		gsap.to( this.loader, { autoAlpha: 0, duration: .3 } );



		let topVideo = document.querySelector( 'video' );
		topVideo.play();

		// force play on ios if low battery
		if ( topVideo.paused ) {

			let playVideo = () => {

				topVideo.play();
				document.body.removeEventListener( 'touchstart', playVideo );

			};

			document.body.addEventListener( 'touchstart', playVideo );

		}




	}


}
