import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin( ScrollTrigger );

export default class ScrollAnimations {

	constructor( targets ) {

		this.currentStyles = {};

		targets.titles.forEach( ( title ) => {

			// skip "be part of our journey"
			if ( title.parentNode.classList.contains( 'email-list' ) ) return;
			// console.log( title.classList );

			gsap.from( title, {
				scrollTrigger: {
					trigger: title,
					scrub: .4,
					start: "top bottom-=50px",
					end: "center center"
				},
				ease: 'power4.out',
				y: 100,
				autoAlpha: 0
			} );

		} );

		targets.cutouts.forEach( ( cutout ) => {



			ScrollTrigger.matchMedia( {
				"(min-width: 1081px)": () => {

					let image = cutout.querySelector( 'img' );
					let text = cutout.querySelector( '.text-wrap' );
					let isFlipped = cutout.classList.contains( 'flipped' );

					this.saveCurrentStyles( cutout, image, text );
					gsap.set( image, { clearProps: 'all' } );
					gsap.set( text, { clearProps: 'all' } );
					gsap.set( cutout, { clearProps: 'all' } );

					gsap.from( image, {
						scrollTrigger: {
							trigger: cutout,
							scrub: .66,
							start: "top bottom-=50px",
							end: "center center",
							// markers: true
						},
						x: isFlipped ? 100 : - 100,
						autoAlpha: 0
					} );

					gsap.from( text, {
						scrollTrigger: {
							trigger: cutout,
							scrub: .66,
							start: "top bottom-=50px",
							end: "center center",
							// markers: true
						},
						x: isFlipped ? - 100 : 100,
						autoAlpha: 0
					} );

					return () => {

						// console.log( 'scrolltrigger match media detach function' );
						// console.log( 'ScrollTrigger leaving desktop' );
						gsap.set( image, { clearProps: 'all' } );
						gsap.set( text, { clearProps: 'all' } );
						gsap.set( cutout, { clearProps: 'all' } );
						this.setCurrentStyles( cutout, image, text );

					};

				}
			} );



		} );

	}

	saveCurrentStyles( cutout, image, text ) {

		cutout.currentStyles = {};
		cutout.currentStyles[ 'cutout' ] = cutout.style.cssText;
		cutout.currentStyles[ 'image' ] = image.style.cssText;
		cutout.currentStyles[ 'text' ] = text.style.cssText;

	}

	setCurrentStyles( cutout, image, text ) {

		cutout.style.cssText = cutout.currentStyles[ 'cutout' ];
		image.style.cssText = cutout.currentStyles[ 'image' ];
		text.style.cssText = cutout.currentStyles[ 'text' ];

	}

}
