import { gsap } from "gsap";
import Swipeable from "./swipeable";
import { isMobile } from "./is-mobile";

const animDuration = .4;

export default class Cutouts {


	constructor( gallery, isDisabled ) {

		if ( ! isDisabled ) {

			let prevButton = gallery.querySelector( '.previous-button' );
			let nextButton = gallery.querySelector( '.next-button' );
			this.currentCutout = gallery.querySelector( '.current-cutout' );
			this.slides = gallery.querySelectorAll( '.row' );
			this.currentIndex = 0;
			this.animating = false;
			this.maxSlide = this.slides.length;
			this.currentSlide, this.nextSlide, this.prevSlide = null;
			this.getSlides();

			this.pool = [];

			const swipeable = new Swipeable( { offset: 10 } );

			this.swipedLeft = false;
			this.swipedRight = false;
			this.isTouching = false;
			this.touchId = null;


			// commented out swipe
			gallery.addEventListener( 'touchstart', e => {

				if ( isMobile() && ! this.isTouching && ! e.target.parentNode.parentNode.classList.contains( 'mobile-controls' )
								&& ! e.target.parentNode.classList.contains( 'mobile-controls' ) ) {

					this.isTouching = true;
					swipeable.touchStart( e );
					this.swipedLeft = false;
					this.swipedRight = false;

				}


			} );
			gallery.addEventListener( 'touchmove', e => {

				if ( isMobile() && ! e.target.parentNode.parentNode.classList.contains( 'mobile-controls' )
								&& ! e.target.parentNode.classList.contains( 'mobile-controls' ) ) {

					if ( ! this.animating ) {

						swipeable.touchMove( ( e ), {
							onLeft: ( e, x ) => {

								gsap.to( this.currentSlide, { x: x, duration: .1 } );
								if ( x <= - 90 ) {

									this.swipedLeft = true;

								}

							},
							onRight: ( e, x ) => {

								gsap.to( this.currentSlide, { x: x, duration: .1 } );
								if ( x >= 90 ) {

									this.swipedRight = true;

								}


							}
						} );

					}

				}

			} );
			gallery.addEventListener( 'touchend', e => {

				if ( isMobile() && ! e.target.parentNode.parentNode.classList.contains( 'mobile-controls' )
								&& ! e.target.parentNode.classList.contains( 'mobile-controls' ) ) {

					swipeable.touchEnd( e );
					this.isTouching = false;
					this.touchId = null;
					if ( this.swipedLeft ) {

						this.swipedLeft = false;
						this.doNext();

					} else if ( this.swipedRight ) {

						this.swipedRight = false;
						this.doPrev();

					} else {

						gsap.to( this.currentSlide, { x: 0 } );

					}

				}

			} );

			prevButton.addEventListener( 'click', ( e ) => {

				this.doPrev();


			} );

			nextButton.addEventListener( 'click', ( e ) => {

				this.doNext();

			} );

		}


	}

	getSlides() {

		this.currentSlide = this.slides[ this.currentIndex ];
		this.nextSlide = this.slides[ this.getNextIndex() ];
		this.prevSlide = this.slides[ this.getPrevIndex() ];

	}

	getNextIndex() {

		return ( this.currentIndex + 1 ) % this.maxSlide;

	}

	getPrevIndex() {

		return this.currentIndex == 0 ? this.maxSlide - 1 : this.currentIndex - 1;

	}

	animateToPrev() {

		this.animating = true;
		this.currentIndex = this.getPrevIndex();
		this.currentCutout.innerText = this.currentIndex + 1;
		this.getSlides();

		gsap.to( this.currentSlide, { x: 0, duration: animDuration } );
		gsap.to( this.nextSlide, { x: "100vw", duration: animDuration, onComplete: () => {

			this.animationComplete();

		} } );
		gsap.set( this.prevSlide, { x: "-100vw" } );

	}

	animateToNext() {

		this.animating = true;
		this.currentIndex = this.getNextIndex();
		this.currentCutout.innerText = this.currentIndex + 1;
		this.getSlides();

		gsap.to( this.currentSlide, { x: 0, duration: animDuration } );
		gsap.set( this.nextSlide, { x: "100vw" } );
		gsap.to( this.prevSlide, { x: "-100vw", duration: animDuration, onComplete: () => {

			this.animationComplete();

		} } );


	}

	animationComplete() {

		this.animating = false;
		if ( this.pool.length ) {

			this[ this.pool.pop() ]();

		}

	}


	doPrev() {

		if ( ! this.animating ) {

			this.animateToPrev();

		} else {

			this.pool.unshift( 'animateToPrev' );

		}

	}

	doNext() {

		if ( ! this.animating ) {

			this.animateToNext();

		} else {

			this.pool.unshift( 'animateToNext' );

		}

	}


}
