import "../styles/styles.scss";
import "../styles/components/tablet.scss";
import "../styles/components/mobile.scss";


import Main from "./components/main";

const main = new Main();

window.addEventListener( 'load', ( e ) => {

	main.loaded();

} );
