import { gsap } from "gsap";

export default class Cards {

	constructor( cards, isDisabled = false ) {


		const btnTextOffset = 16;
		const animDuration = .33;

		cards.map( ( card ) => {

			let cardBottom = card.querySelector( '.card-bottom' );
			let cardContent = card.querySelector( '.card-content' );

			let moreButton = card.querySelector( '.more' );
			let moreText = card.querySelector( '.more .button-text-inner' );
			let lessButton = card.querySelector( '.less' );
			let lessText = card.querySelector( '.less .button-text-inner' );
			gsap.set( lessButton, { autoAlpha: 0 } );
			gsap.set( cardContent, { autoAlpha: 0 } );
			let ogPaddingBottom = window.getComputedStyle( cardBottom ).paddingBottom;
			let resizeDebounceTimer;
			let isResizing = false;
			let prevWidth = window.innerWidth;
			let debounced = false;

			window.addEventListener( 'resize', () => {

				if ( ! isResizing && prevWidth !== window.innerWidth ) {

					clearTimeout( resizeDebounceTimer );
					gsap.set( card, { clearProps: 'all' } );
					isResizing = true;
					resizeDebounceTimer = setTimeout( function () {

						isResizing = false;

					}, 250 );
					prevWidth = window.innerWidth;

				}


			} );

			if ( ! isDisabled ) {

				moreButton.addEventListener( 'click', ( e ) => {

					if ( ! debounced ) {

						debounced = true;
						let ogButtonHeight = window.getComputedStyle( moreButton ).height;
						gsap.set( cardContent, { autoAlpha: 1 } );
						gsap.set( card, { height: ogButtonHeight } );
						gsap.to( cardBottom, { marginTop: 0, paddingBottom: "81.6%", duration: animDuration } ); //value from css

						gsap.to( moreText, {
							y: - btnTextOffset, duration: .3, onComplete: () => {

								gsap.set( moreButton, { autoAlpha: 0 } );

							}
						} );
						gsap.set( lessButton, { autoAlpha: 1 } );
						gsap.set( lessText, { y: btnTextOffset } );
						gsap.to( lessText, { y: 0, duration: animDuration } );
						setTimeout( () => {

							debounced = false;

						}, animDuration * 1000 + 100 );

					}



				} );

				lessButton.addEventListener( 'click', ( e ) => {

					if ( ! debounced ) {

						debounced = true;
						gsap.to( cardContent, { autoAlpha: 0, duration: animDuration } );
						gsap.to( cardBottom, { marginTop: "81.6%", paddingBottom: ogPaddingBottom, duration: animDuration } ); //value from css

						gsap.to( lessText, {
							y: btnTextOffset, duration: animDuration, onComplete: () => {

								gsap.set( lessButton, { autoAlpha: 0 } );

							}
						} );
						gsap.set( moreButton, { autoAlpha: 1 } );
						gsap.to( moreText, { y: 0, duration: animDuration } );
						setTimeout( () => {

							debounced = false;

						}, animDuration * 1000 + 100 );

					}

				} );

			}

		} );

	}





}
